import * as i1 from '@angular/animations';
import { style, animate } from '@angular/animations';
import * as i0 from '@angular/core';
import { EventEmitter, Directive, Output, HostBinding, Input, NgModule } from '@angular/core';
const COLLAPSE_ANIMATION_TIMING = '400ms cubic-bezier(0.4,0.0,0.2,1)';
const expandAnimation = [style({
  height: 0,
  visibility: 'hidden'
}), animate(COLLAPSE_ANIMATION_TIMING, style({
  height: '*',
  visibility: 'visible'
}))];
const collapseAnimation = [style({
  height: '*',
  visibility: 'visible'
}), animate(COLLAPSE_ANIMATION_TIMING, style({
  height: 0,
  visibility: 'hidden'
}))];
class CollapseDirective {
  set display(value) {
    this._display = value;
    if (value === 'none') {
      this.hide();
      return;
    }
    this.isAnimated ? this.toggle() : this.show();
  }
  /** A flag indicating visibility of content (shown or hidden) */
  set collapse(value) {
    this.collapseNewValue = value;
    if (!this._player || this._isAnimationDone) {
      this.isExpanded = value;
      this.toggle();
    }
  }
  get collapse() {
    return this.isExpanded;
  }
  constructor(_el, _renderer, _builder) {
    this._el = _el;
    this._renderer = _renderer;
    /** This event fires as soon as content collapses */
    this.collapsed = new EventEmitter();
    /** This event fires when collapsing is started */
    this.collapses = new EventEmitter();
    /** This event fires as soon as content becomes visible */
    this.expanded = new EventEmitter();
    /** This event fires when expansion is started */
    this.expands = new EventEmitter();
    // shown
    this.isExpanded = true;
    this.collapseNewValue = true;
    // hidden
    this.isCollapsed = false;
    // stale state
    this.isCollapse = true;
    // animation state
    this.isCollapsing = false;
    /** turn on/off animation */
    this.isAnimated = false;
    this._display = 'block';
    this._stylesLoaded = false;
    this._COLLAPSE_ACTION_NAME = 'collapse';
    this._EXPAND_ACTION_NAME = 'expand';
    this._factoryCollapseAnimation = _builder.build(collapseAnimation);
    this._factoryExpandAnimation = _builder.build(expandAnimation);
  }
  ngAfterViewChecked() {
    this._stylesLoaded = true;
    if (!this._player || !this._isAnimationDone) {
      return;
    }
    this._player.reset();
    this._renderer.setStyle(this._el.nativeElement, 'height', '*');
  }
  /** allows to manually toggle content visibility */
  toggle() {
    if (this.isExpanded) {
      this.hide();
    } else {
      this.show();
    }
  }
  /** allows to manually hide content */
  hide() {
    this.isCollapsing = true;
    this.isExpanded = false;
    this.isCollapsed = true;
    this.isCollapsing = false;
    this.collapses.emit(this);
    this._isAnimationDone = false;
    this.animationRun(this.isAnimated, this._COLLAPSE_ACTION_NAME)(() => {
      this._isAnimationDone = true;
      if (this.collapseNewValue !== this.isCollapsed && this.isAnimated) {
        this.show();
        return;
      }
      this.collapsed.emit(this);
      this._renderer.setStyle(this._el.nativeElement, 'display', 'none');
    });
  }
  /** allows to manually show collapsed content */
  show() {
    this._renderer.setStyle(this._el.nativeElement, 'display', this._display);
    this.isCollapsing = true;
    this.isExpanded = true;
    this.isCollapsed = false;
    this.isCollapsing = false;
    this.expands.emit(this);
    this._isAnimationDone = false;
    this.animationRun(this.isAnimated, this._EXPAND_ACTION_NAME)(() => {
      this._isAnimationDone = true;
      if (this.collapseNewValue !== this.isCollapsed && this.isAnimated) {
        this.hide();
        return;
      }
      this.expanded.emit(this);
      this._renderer.removeStyle(this._el.nativeElement, 'overflow');
    });
  }
  animationRun(isAnimated, action) {
    if (!isAnimated || !this._stylesLoaded) {
      return callback => callback();
    }
    this._renderer.setStyle(this._el.nativeElement, 'overflow', 'hidden');
    this._renderer.addClass(this._el.nativeElement, 'collapse');
    const factoryAnimation = action === this._EXPAND_ACTION_NAME ? this._factoryExpandAnimation : this._factoryCollapseAnimation;
    if (this._player) {
      this._player.reset();
    }
    this._player = factoryAnimation.create(this._el.nativeElement);
    this._player.play();
    return callback => this._player?.onDone(callback);
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "19.0.1",
      ngImport: i0,
      type: CollapseDirective,
      deps: [{
        token: i0.ElementRef
      }, {
        token: i0.Renderer2
      }, {
        token: i1.AnimationBuilder
      }],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "19.0.1",
      type: CollapseDirective,
      isStandalone: true,
      selector: "[collapse]",
      inputs: {
        display: "display",
        isAnimated: "isAnimated",
        collapse: "collapse"
      },
      outputs: {
        collapsed: "collapsed",
        collapses: "collapses",
        expanded: "expanded",
        expands: "expands"
      },
      host: {
        properties: {
          "class.collapse": "this.isCollapse",
          "class.in": "this.isExpanded",
          "class.show": "this.isExpanded",
          "attr.aria-hidden": "this.isCollapsed",
          "class.collapsing": "this.isCollapsing"
        }
      },
      exportAs: ["bs-collapse"],
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.0.1",
  ngImport: i0,
  type: CollapseDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[collapse]',
      exportAs: 'bs-collapse',
      host: {
        '[class.collapse]': 'true'
      },
      standalone: true
    }]
  }],
  ctorParameters: () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: i1.AnimationBuilder
  }],
  propDecorators: {
    collapsed: [{
      type: Output
    }],
    collapses: [{
      type: Output
    }],
    expanded: [{
      type: Output
    }],
    expands: [{
      type: Output
    }],
    isExpanded: [{
      type: HostBinding,
      args: ['class.in']
    }, {
      type: HostBinding,
      args: ['class.show']
    }],
    isCollapsed: [{
      type: HostBinding,
      args: ['attr.aria-hidden']
    }],
    isCollapse: [{
      type: HostBinding,
      args: ['class.collapse']
    }],
    isCollapsing: [{
      type: HostBinding,
      args: ['class.collapsing']
    }],
    display: [{
      type: Input
    }],
    isAnimated: [{
      type: Input
    }],
    collapse: [{
      type: Input
    }]
  }
});
class CollapseModule {
  // @deprecated method not required anymore, will be deleted in v19.0.0
  static forRoot() {
    return {
      ngModule: CollapseModule,
      providers: []
    };
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "19.0.1",
      ngImport: i0,
      type: CollapseModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "19.0.1",
      ngImport: i0,
      type: CollapseModule,
      imports: [CollapseDirective],
      exports: [CollapseDirective]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "19.0.1",
      ngImport: i0,
      type: CollapseModule
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.0.1",
  ngImport: i0,
  type: CollapseModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [CollapseDirective],
      exports: [CollapseDirective]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { CollapseDirective, CollapseModule };
