<div class="">
  <div class="form-group">
    <label class="form-control-label">Repeat</label>
    <select class="form-control" [(ngModel)]="selectedFrequency" (ngModelChange)="frequencyChangeHandler()">
      <option *ngFor="let item of frequencies" [ngValue]="item.name">
        {{ item.name }}
      </option>
    </select>
  </div>

  <!-- Weeks -->
  <ng-template [ngIf]="selectedFrequency === 'weekly'">
    <div class="form-group">
      <p-selectButton [options]="dayList" optionLabel="value" optionValue="number" multiple="multiple"
        [(ngModel)]="dayOfWeek" (ngModelChange)="changeHandler()"></p-selectButton>
    </div>
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="form-control-label">Hour</label>
          <select class="form-control" [(ngModel)]="hours" (ngModelChange)="changeHandler()">
            <option *ngFor="let item of selectOptions.hours" [ngValue]="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label class="form-control-label">Minute</label>
          <select class="form-control" [(ngModel)]="minutes" (ngModelChange)="changeHandler()">
            <option *ngFor="let item of selectOptions.minutes" [ngValue]="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- Month -->
  <ng-template [ngIf]="selectedFrequency === 'monthly'">
    <div class="form-group">
      <label class="form-control-label">Day of Month</label>
      <select class="form-control" [(ngModel)]="dayOfMonth" (ngModelChange)="changeHandler()">
        <option *ngFor="let item of selectOptions.monthDays" [ngValue]="item">
          {{ item }}
        </option>
      </select>
    </div>
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="form-control-label">Hour</label>
          <select class="form-control" [(ngModel)]="hours" (ngModelChange)="changeHandler()">
            <option *ngFor="let item of selectOptions.hours" [ngValue]="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label class="form-control-label">Minute</label>
          <select class="form-control" [(ngModel)]="minutes" (ngModelChange)="changeHandler()">
            <option *ngFor="let item of selectOptions.minutes" [ngValue]="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </ng-template>

</div>