<nav
  class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom"
  id="navbar-main">
  <div class="container-fluid">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav align-items-center ml-md-auto">
        <li class="nav-item d-xl-none">
          <!-- Sidenav toggler -->
          <div
            class="pr-3 sidenav-toggler sidenav-toggler-dark"
            data-action="sidenav-pin"
            data-target="#sidenav-main"
            (click)="openSidebar()">
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </li>
      </ul>
      <div
        id="breadcrumb-component"
        class="tab-pane fade show active ml--3 d-none d-sm-block flex-fill"
        role="tabpanel"
        aria-labelledby="breadcrumb-component-tab">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-dark bg-transparent">
            <li class="breadcrumb-item text-sm">
              <a routerLink="/"><i class="fas fa-home"></i></a>
            </li>
            <ng-container *ngFor="let item of breadcrumbs$ | async">
              <li *ngIf="!item?.isPage" class="breadcrumb-item text-sm">
                <a
                  [routerLink]="item?.link"
                  [queryParams]="item.queryParams || ''"
                  >{{ item?.title }}</a
                >
              </li>
              <li
                *ngIf="item?.isPage"
                class="breadcrumb-item active text-sm"
                aria-current="page">
                {{ item?.title }}
              </li>
            </ng-container>
          </ol>
        </nav>
      </div>
      <!-- Search form -->
      <!-- <form
        class="navbar-search navbar-search-light form-inline mr-sm-3"
        id="navbar-search-main"
        (keyup.enter)="search($event)">
        <div class="form-group mb-0" [ngClass]="{ focused: focus === true }">
          <div class="input-group input-group-alternative input-group-merge">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><i class="fas fa-search"></i
              ></span>
            </div>
            <input
              class="form-control"
              placeholder="Search buildings"
              type="text"
              (focus)="focus = true"
              (blur)="focus = false" />
          </div>
        </div>
        <button type="button" class="close" (click)="closeSearch()" data-action="search-close"
          data-target="#navbar-search-main" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </form> -->
      <!-- Navbar links -->

      <!-- User -->
      <ul class="navbar-nav align-items-center ml-auto ml-md-0">
        <li class="nav-item dropdown" dropdown placement="bottom-right">
          <a
            class="nav-link pr-0 dropdown-toggle"
            role="button"
            dropdownToggle
            data-testid="user-btn">
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img
                  [src]="
                    'https://eu.ui-avatars.com/api/?name=' +
                    currentUser?.firstName +
                    '+' +
                    currentUser?.lastName +
                    '.jpg'
                  " />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold"
                  >{{ currentUser?.firstName }}
                  {{ currentUser?.lastName }}</span
                >
                <div class="text-xs">Account {{ tenantId }}</div>
              </div>
            </div>
          </a>
          <div
            class="dropdown-menu dropdown-menu-arrow dropdown-menu-right"
            *dropdownMenu>
            <div class="dropdown-header noti-title" *ngIf="accounts.size > 1">
              <h6 class="text-overflow m-0">Switch to</h6>
            </div>
            <ng-container *ngFor="let account of accounts | keyvalue">
              <a
                class="dropdown-item"
                (click)="onSwitch(account)"
                *ngIf="account.key !== accountKey">
                <i class="fa-solid fa-building-user"></i>
                <span>
                  {{ account.value.tenantId.toUpperCase() }} -
                  {{ account.value.firstName }} {{ account.value.lastName }}
                </span>
              </a>
            </ng-container>
            <div class="dropdown-divider" *ngIf="accounts.size > 1"></div>
            <a class="dropdown-item" [routerLink]="['/auth', 'login']">
              <i class="fa-solid fa-user-plus"></i>Add Account
            </a>
            <div class="dropdown-divider"></div>
            <a (click)="logout()" class="dropdown-item">
              <i class="ni ni-user-run"></i> <span>Logout</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div
  *ngIf="sidenavOpen === true"
  class="backdrop d-xl-none"
  (click)="toggleSidenav()"></div>
