import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  tap,
} from 'rxjs/operators';

@Component({
    selector: 'app-search-filter',
    templateUrl: './search-filter.component.html',
    styleUrls: ['./search-filter.component.scss'],
    standalone: false
})
export class SearchFilterComponent implements AfterViewInit {
  @ViewChild('searchInput') searchInput: ElementRef;
  @Input() placeholder: string = 'Search...';
  @Input() data: any[];
  @Input() keys: string[] = [];
  @Output() filteredData = new EventEmitter<{
    searchString: string;
    data: any[];
  }>();
  @Output() resetData = new EventEmitter<boolean>();

  ngAfterViewInit(): void {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((event: any) => {
          if (event.keyCode != 13) {
            if (
              event.target.value != null &&
              event.target.value != undefined &&
              event.target.value.trim() == ''
            ) {
              this.resetData.next(true);
            } else {
              this.sendFilteredData(event.target.value);
            }
          }
        })
      )
      .subscribe();
  }

  sendFilteredData(searchString: string) {
    this.filteredData.next({
      searchString,
      data: this.data.filter(ele => {
        return this.searchData(ele, searchString);
      }),
    });
  }

  searchData(obj, searchString) {
    let isValid = false;
    for (const key in obj) {
      if (this.keys.length > 0 && !this.keys.includes(key)) {
        continue;
      }
      if (typeof obj[key] == 'object') {
        isValid = this.searchData(obj[key], searchString);
      }
      if (
        obj[key] &&
        obj[key].toString().toUpperCase().indexOf(searchString.toUpperCase()) !=
          -1
      ) {
        return true;
      }
    }
    return isValid;
  }
}
