import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import cronstrue from 'cronstrue';
import { Days, frequencies } from 'src/app/utilities/constants';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const CRON_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CronInputComponent),
  multi: true,
};

@Component({
    selector: 'app-cron-input',
    templateUrl: './cron-input.component.html',
    providers: [CRON_VALUE_ACCESSOR],
    standalone: false
})
export class CronInputComponent implements ControlValueAccessor {
  @Input() disabled: boolean = false;
  @Output() valueChanges = new EventEmitter<any>();
  @Output() cronOutput = new EventEmitter<{
    error: boolean;
    message: string;
  }>();

  public cronExp = '';
  cronStr = '';
  cronError = '';

  frequencies = frequencies;
  dayList = Days;
  now: Date = new Date();

  selectedFrequency: 'weekly' | 'monthly' = 'weekly';

  minutes: number | null | any = null;
  hours: number | null | any = null;
  dayOfMonth: number | null | string | any = '*';
  dayOfWeek: any = [];
  month: number | null | any = null;

  selectOptions = this.getSelectOptions();

  constructor() {}

  private getRange(start: number, end: number): number[] {
    const length = end - start + 1;
    return Array.apply(null, Array(length)).map((_, i) => i + start);
  }

  getSelectOptions() {
    return {
      months: this.getRange(1, 12),
      minutes: this.getRange(0, 59),
      hours: this.getRange(0, 23),
      monthDays: this.getRange(1, 31),
    };
  }

  frequencyChangeHandler() {
    this.minutes = null;
    this.hours = null;
    this.dayOfWeek = null;
    this.dayOfMonth = null;
    this.month = null;
    if (this.selectedFrequency === 'weekly') {
      this.dayOfMonth = '*';
      this.dayOfWeek = [1];
      this.hours = 8;
      this.minutes = 30;
    } else if (this.selectedFrequency === 'monthly') {
      this.dayOfMonth = 1;
      this.hours = 8;
      this.minutes = 30;
    }
    this.changeHandler();
  }

  changeHandler() {
    this.cronStr = '';
    this.cronError = '';
    let minute = this.minutes;
    let hour = this.hours;
    let month = '*';
    let dayOfWeek =
      this.dayOfWeek && this.dayOfWeek?.length > 0 ? this.dayOfWeek.sort() : '*';
    let dayOfMonth = this.dayOfMonth || '*';
    const exp = `${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`;
    try {
      this.cronStr = cronstrue.toString(exp);
    } catch (err) {
      console.error('Cron String Error:', err);
      this.cronError = err;
    }
    this.cronOutput.emit({
      error: this.cronError ? true : false,
      message: this.cronError ? this.cronError : `Repeats ${this.cronStr.replace('At', 'at').replace(', only', '')}`,
    });

    if (!this.cronError) {
      this.onChange(exp);
      this.valueChanges.emit(exp);
    }
  }

  calculateFromCron() {
    let value = this.cronExp?.split(' ');
    console.log('value:', value);
    if (value?.length === 5) {
      if (this.dayOfMonth = value[2] !== '*') {
        this.selectedFrequency = 'monthly'
      }
      this.minutes = value[0] !== '*' ? parseInt(value[0]) : '';
      this.hours = value[1] !== '*' ? parseInt(value[1]) : '';
      this.dayOfMonth = value[2] !== '*' ? parseInt(value[2]) : '';
      this.month = value[3] !== '*' ? parseInt(value[3]) : '';
      this.dayOfWeek = value[4] !== '*' ? value[4].split(',').map(Number) : [];
      this.changeHandler();
    }
  }

  /*
   * ControlValueAccessor
   */
  onChange = (_: any) => {};
  onTouched = () => {};

  writeValue(obj: any): void {
    // console.log('obj:', obj);
    this.cronExp = obj;
    this.calculateFromCron();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
