/* src/app/components/search-filter/search-filter.component.scss */
.search-group {
  position: relative;
}
.search-group i {
  top: 50%;
  font-size: 16px;
  left: 15px;
  position: absolute;
  transform: translateY(-50%);
}
.search-group input {
  border-radius: 25px;
  padding-left: 40px;
}
/*# sourceMappingURL=search-filter.component.css.map */
